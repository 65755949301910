import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/notice/list',
    method: 'get',
    params
  })
}


export function createAPI(data) {
  return http({
    url: '/company/notice/add',
    method: 'post',
    data
  })
}


export function editAPI(data) {
  return http({
    url: '/company/notice/edit',
    method: 'post',
    data
  })
}

export function modifyIsPublishAPI(data) {
  return http({
    url: '/company/notice/modifyIsPublish',
    method: 'post',
    data
  })
}


export function delAPI(id) {
  return http({
    url: '/company/notice/del',
    method: 'post',
    data: {id}
  })
}

export function detailAPI(id) {
  return http({
    url: '/company/notice/detail',
    method: 'get',
    params: {id}
  })
}
